<template>
	<div class="chat" :type="type" :index="index">
		<div class="avatar ai" :style="avatarImg">
		</div>
		<div class="content" @click="onSelect">
			<div class="control" @click="onCopy"><i class="fas fa-copy"></i></div>
			<div ref="inner" class="inner">{{content}}</div>
		</div>
		<div class="avatar me" :style="userImg"></div>
		<div class="extra_info" v-if="!!aiName || !!chatTarget || !!aiModel">
			<span class="name">{{aiName}} <span class="target">{{ chatTarget }}</span></span>
			<span class="model">{{aiModel}}</span>
		</div>
	</div>
</template>

<style scoped>
.chat {
	position: relative;
	margin-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
}
.chat .avatar {
	position: absolute;
	top: 10px;
	display: none;
	width: 60px;
	height: 60px;
	padding: 10px;
	box-shadow: inset 2px 2px 5px rgba(25, 25, 25, 0.4);
	border-radius: 50%;
	text-align: center;
	font-size: 25px;
	line-height: 40px;
	color: rgba(248, 244, 237, 1.0);
	background-size: cover;
	overflow: hidden;
}
.chat .avatar.ai {
	left: 10px;
}
.chat[type="ai"] .avatar.ai {
	display: block;
}
.chat .avatar.me {
	right: 10px;
	background-color: rgb(201, 96, 255);
	text-shadow: 0px 0px 2px black;
}
.chat[type="me"] .avatar.me {
	display: block;
}
.chat .content {
	position: relative;
	top: 5px;
	margin-left: 70px;
	margin-right: 70px;
	padding: 10px;
	text-align: left;
	font-size: 18px;
	color: rgba(248, 244, 237, 1.0);
}
.chat[type="process"] .content {
	min-height: 0px;
	padding: 0px;
	color: white;
	font-size: 14px;
	font-style: italic;
	word-break: break-word;
}
.chat[type="error"] .content {
	min-height: 0px;
	padding: 0px;
	color: red;
	font-size: 14px;
	font-weight: bolder;
	text-align: center;
}
.chat[type="ai"] .content,
.chat[type="me"] .content {
	padding-top: 15px;
	padding-bottom: 15px;
	min-height: 80px;
	border-radius: 15px;
	box-shadow: 0px 0px 2px black;
	overflow: hidden;
}
.chat[type="ai"] .content p:first-child,
.chat[type="me"] .content p:first-child {
	margin-top: 0px;
}
.chat[type="ai"] .content p:last-child,
.chat[type="me"] .content p:last-child {
	margin-bottom: 0px;
}
.chat[type="ai"] .content {
	border-top-left-radius: 0px;
	background-color: rgba(0, 0, 0, 0.24);
}
.chat[type="me"] .content {
	border-top-right-radius: 0px;
	background-color: rgba(255, 255, 255, 0.08);
}
.chat .content .inner {
	margin: 0px;
	padding: 0px;
}
.chat[type="process"] .content .control,
.chat[type="error"] .content .control {
	display: none;
}
.chat .content .control {
	position: absolute;
	top: -40px;
	right: 0px;
	padding: 5px 8px;
	border-radius: 0px 0px 8px 8px;
	border: 1px solid black;
	border-top: none;
	background-color: rgba(67, 67, 67, 0.5);
	backdrop-filter: blur(2px);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
	cursor: pointer;
	transition: top 300ms ease-in-out;
}
.chat .content:hover .control {
	top: 0px;
}
.chat .extra_info {
	display: none;
}
.chat[type="ai"] .extra_info {
	position: absolute;
	display: none;
	top: 5px;
	left: 80px;
	width: auto;
	padding: 0px 5px;
	background-color: rgba(23, 23, 23, 0.8);
	box-shadow: 2px 2px 5px black;
	font-size: 16px;
	text-align: left;
	color: white;
	text-shadow: 1px 1px 2px black;
}
.chat[type="ai"]:hover .extra_info {
	display: block;
}
.chat[type="ai"] .extra_info .name,
.chat[type="ai"] .extra_info .model {
	display: block;
	margin-top: 5px;
	margin-bottom: 5px;
}
.chat[type="ai"] .extra_info .name {
	font-weight: bolder;
}
.chat[type="ai"] .extra_info .name .target,
.chat[type="ai"] .extra_info .model {
	font-size: 0.8em;
}
.chat[type="mention"] .content {
	white-space: break-spaces;
}
.chat[type="mention"] .content .control {
	display: none;
}
</style>
<style>
.chat[type="process"] .content a,
.chat[type="process"] .content a:visited,
.chat[type="process"] .content a:hover,
.chat[type="process"] .content a:active {
	color: white;
}
.chat[type="error"] .content a,
.chat[type="error"] .content a:visited,
.chat[type="error"] .content a:hover,
.chat[type="error"] .content a:active {
	color: red;
}
.chat .content .inner.markup p:first-child {
	margin-top: 0px;
}
.chat .content .inner.markup p:last-child {
	margin-bottom: 0px;
}
</style>

<script>
const ActionNameList = {
	search: "搜索",
	browse: "读取网页",
	summary: "概述网页",
	think: "思考",
};

export default {
	name: 'Chat',
	props: ["item", "userImg", "avatarImg"],
	data () {
		return {
			type: "process",
			content: "...",
			aiName: '',
			aiModel: '',
			chatTarget: '',
			index: 0,
			isRunning: false,
		}
	},
	async mounted () {
		await this.update();
		this.$emit('updated');

		if (this.item[1].extra) {
			let showName = 'AI';
			if (!!this.item[1].extra.name) {
				showName = this.item[1].extra.name;
			}
			else if (!!this.item[1].extra.ai && !!window._avatarList) {
				let name = this.item[1].extra.ai;
				name = window._avatarList.filter(ava => ava.url === name)[0];
				if (!!name) showName = name.name;
			}
			this.aiName = showName;
			if (!this.item[1].target) {
				this.chatTarget = '';
			}
			else if (this.item[1].target === 'EVERYONE') {
				this.chatTarget = '(对所有人说)';
			}
			else {
				this.chatTarget = '(对' + this.item[1].target + '说)';
			}
			if (!!this.item[1].extra.model) {
				this.aiModel = this.item[1].extra.model;
			}
		}
	},
	methods: {
		onSelect (evt) {
			if (!evt.target.classList.contains('aiHint')) return;
			this.$emit('chooseAIHint', evt.target.innerText);
		},
		onCopy () {
			var ok = false;
			if (!!navigator.clipboard) {
				try {
					var ctx = this._content.reply || this._content.content;
					if (!ctx) {
						ctx = this._content;
					}
					navigator.clipboard.writeText(ctx);
					ok = true;
				}
				catch (err) {
					console.error(err);
				}
			}
			if (!ok) {
				let sel = document.getSelection();
				let range = sel.getRangeAt(0);
				let copy = document.createRange();
				copy.selectNodeContents(this.$refs.inner);
				sel.removeAllRanges();
				sel.addRange(copy);
				document.execCommand('copy', false);
				sel.removeAllRanges();
				sel.addRange(range);
			}
			notify('Content Copied!', undefined, 'top-right', 'success', 2000);
		},
		async update () {
			this._content = this.item[1];
			if (this.item[0] === 0) {
				this.type = 'me';
			}
			else if (this.item[0] === 1) {
				this.type = 'ai';
			}
			else if (this.item[0] === 3) {
				this.type = 'error';
			}
			else if (this.item[0] === 4) {
				this.type = 'ai';
			}
			else if (this.item[0] === 5) {
				this.type = 'mention';
			}
			else {
				this.type = 'process';
			}
			this.index = this.item[2];

			if (this.item[0] === 2 && this.item[1][3] === 'process') {
				let data = this.item[1];
				let title = ActionNameList[data[0]] || data[0];
				if (data[0] === 'search') {
					let href = encodeURIComponent(data[1].map(url => url).join('  '));
					title = '<a href="https://www.google.com/search?hl=en-US&source=hp&q=' + href + '" target="_blank">' + title + '(' + data[1].join(', ') + ')</a>';
				}
				else if (['browse', 'summary'].includes(data[0].toLowerCase())) {
					data[1] = data[1].map(item => {
						if (item.match(/^\s*(ht|f)tps?:\/\//i)) {
							item = item.replace(/^\s+|\s+$/gi, '');
							item = '<a href="' + item + '" target="_blank">' + item + '</a>';
						}
						return item;
					});
					title += '(' + data[1].join(', ') + ')';
				}
				else {
					title += '(' + data[1].join(', ') + ')';
				}
				let status = this.item[1][4], running = true;
				if (status === 'start') {
					title = '<i class="fas fa-spinner fa-pulse"></i>&nbsp;正在执行任务：<br>' + title;
				}
				else if (status === 'success') {
					title = '<i class="fas fa-check" style="color:green"></i>&nbsp;任务已完成：<br>' + title;
					running = false;
				}
				else if (status === 'failed') {
					title = '<i class="fas fa-times" style="color:red"></i>&nbsp;任务失败：<br>' + title;
					running = false;
				}
				else if (status === 'stopped') {
					title = '<i class="fas fa-ban"></i>&nbsp;任务已终止：<br>' + title;
					running = false;
				}
				else {
					return;
				}
				this.$refs.inner.innerHTML = title;
				this.$el._action = this.item[1][2];
				this.$el._running = running;
				this.$emit('updated');
				return;
			}

			if (this.item[0] === 4) {
				this.isRunning = true;
				this.$refs.inner.innerHTML = `<span>${this.item[1]}&nbsp;</span><i class="fas fa-spinner fa-pulse"></i>`;
			}
			else if (this.item[0] > 1) {
				this.content = this.item[1];
			}
			else {
				while (true) {
					await wait(100);
					if (!!window.MarkUp) break;
				}

				this.$refs.inner.classList.add('markup');

				let ctx, hasExtra = false;
				if (typeof this.item[1] === 'string') {
					ctx = this.item[1];
				}
				else {
					ctx = this.item[1].reply || this.item[1].content || '（无言）';
					if (!!this.item[1].emotion) {
						ctx = ctx + '\n\n*(表情：' + this.item[1].emotion + ')*';
						hasExtra = true;
					}
					if (!!this.item[1].action) {
						ctx = ctx + '\n\n*(动作：' + this.item[1].action + ')*';
						hasExtra = true;
					}
					if (!!this.item[1].target) {
						let tgt = this.item[1].target;
						if (!!tgt) {
							if (['all', 'everyone', 'every one'].includes(tgt.toLowerCase())) tgt = '所有人';
							ctx = '*(对' + tgt + '说)*\n\n' + ctx;
							hasExtra = true;
						}
					}
					if (!!this.item[1].environment) {
						ctx = '*(场景切换：' + this.item[1].environment + ')*\n\n' + ctx;
						hasExtra = true;
					}
				}

				let content;
				try {
					content = MarkUp.parse(ctx).replace(/<\/?(article|section)>/gi, '');
					content = content.replace(/^\s*|\s*$/g, '');
					if (hasExtra) {
						content = content.replace(/<p>\s*<em>[\w\W]*?<\/em>\s*<\/p>/gi, (m) => {
							return m.replace(/<p>/i, '<p style="font-size: 0.8em;color:rgb(155,155,155);margin-bottom:0px;margin-top:0px;">');
						});
					}
					this.$refs.inner.innerHTML = content;
				}
				catch (err) {
					content = [];
					this.item[1].replace(/<reply>\s*([\w\W]+?)\s*<\/reply>|<thought>\s*([\w\W]+?)\s*<\/thought>/gi, (m, i, j) => content.push(i || j));
					if (content.length === 0) {
						this.$refs.inner.innerText = this.item[1];
					}
					else {
						content = content.join('\n\n');
						this.$refs.inner.innerText = content;
					}
				}
				await wait();

				var headers = this.$refs.inner.querySelectorAll('h4');
				[].forEach.call(headers, item => {
					if (!item.innerText.match(/了解更多：/)) return;
					item = item.nextSibling;
					if (!item) return;
					if (item.tagName !== 'OL' && item.tagName !== 'UL') return;
					[].forEach.call(item.querySelectorAll('li'), item => {
						item.classList.add('aiHint');
					});
				});
			}

			this.$emit('updated');
		}
	},
}
</script>