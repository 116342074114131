<template>
	<TitleBar :isChat="true" :title="title" @goBack="goBack" @changeTitle="changeTitle" @download="onDownload" />
	<div class="conversation scrollable">
		<ChatBox v-for="item in list" ref="chatBox"
			:key="item[2]" :item="item" :userImg="userImg" :avatarImg="avatarImg"
			@updated="updatePosition" @chooseAIHint="onInput"
		/>
	</div>
	<Inputter ref="inputter" @onInput="onInput" />
	<div class="bottom"></div>
</template>

<style scoped>
.titlebar {
	position: absolute;
	z-index: 1;
	top: 0px;
	width: 100%;
}
.conversation {
	position: absolute;
	z-index: 0;
	top: 64px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 75px;
}
.bottom {
	position: absolute;
	z-index: 1;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 20px;
	background-color: rgb(49, 51, 57);
	background: linear-gradient(to top, rgba(49, 51, 57, 1.0) 0%, rgba(49, 51, 57, 0.75) 50%, rgba(49, 51, 57, 0.0));
}
</style>

<script>
// @ is an alias to /src
import TitleBar from '@/components/titleBar.vue';
import ChatBox from '@/components/chat.vue';
import Inputter from '@/components/inputter.vue';

const AvatarImg = {
	"h1": '/avatar/h1.png',
	"h2": '/avatar/h2.png',
	"s1": '/avatar/s1.png',
	"s2": '/avatar/s2.png',
};

var current;

export default {
	name: 'Chat',
	components: {
		TitleBar,
		ChatBox,
		Inputter
	},
	props: ['chatID'],
	data () {
		return {
			title: 'AI个人健康助理',
			list: [],
			level: 0,
			userImg: '',
			avatarImg: '',
		}
	},
	async mounted () {
		current = this;
		this.processList = {};
		global.onUpdateProcess = msg => {
			if (current !== this) return;
			this.onMessage(msg);
		};

		var color = [];
		color[0] = Math.round(Math.random() * 130) + 70;
		color[1] = Math.round(Math.random() * 130) + 70;
		color[2] = Math.round(Math.random() * 130) + 70;
		this.userImg = 'background-color: rgb(' + color.join(',') + ');background-image: url("' + staticHost + '/avatar/user.png")';

		this._index = 0;

		var conversation = await DataCenter.get(DBNames.assistant, 'history', this.chatID);
		if (!conversation) {
			conversation = localStorage.get('chat-' + this.chatID, null);
		}
		if (!conversation) {
			this.$router.push({ path: '/' });
			return;
		}
		this.conversation = conversation;
		this.title = conversation.title;
		conversation.history.forEach(item => {
			item[2] = this._index;
			this._index ++;
		});
		this.list.splice(0);
		this.list.push(...conversation.history);
		this.model = conversation.model || DefaultAIModel;
		this.level = conversation.level || DefaultAILevel;
		this.expert = conversation.expert || DefaultExpert;
		var avatarImg = this.expert === 'science' ? 's' : 'h';
		avatarImg += this.level === 2 ? '2' : '1';
		avatarImg = staticHost + AvatarImg[avatarImg];
		this.avatarImg = 'background-image: url("' + avatarImg + '")'

		var last = conversation.history[conversation.history.length - 1];
		if (!!last && last[0] === 0) {
			this.appendChat(last[1]);
		}
	},
	unmounted () {
		if (current === this) current = null;
	},
	methods: {
		async onMessage (msg) {
			if (msg.status === 'mention') {
				let result = msg.action;
				console.log(result);
				if (this.level > 0) {
					let json = parseXMLV1(result);
					if (typeof json !== 'string') {
						result = json.reply;
						if (json.questions) {
							result += '\n\n#### 请问：\n\n';
							result += json.questions.map((line, i) => (i + 1) + '. ' + line).join('\n');
						}
						if (json.hints) {
							result += '\n\n\n----\n\n\n#### 了解更多：\n\n';
							result += json.hints.map((line, i) => (i + 1) + '. ' + line).join('\n');
						}
					}
					else {
						result = json;
					}
				}
				if (!!result) this.addChat(1, result);
			}
			else if (msg.status === 'start') {
				console.log(msg.action);
				msg.action[3] = 'process';
				msg.action[4] = 'start';
				this.addChat(2, msg.action);
				await wait();
				let target = null;
				this.$refs.chatBox.some(box => {
					if (box.$el._action === msg.action[2]) {
						target = box;
						return true;
					}
				});
				if (!!target) {
					this.processList[msg.action[2]] = target;
				}
			}
			else {
				console.log(msg.action, msg.status);
				let box = msg.action;
				if (!box) return;
				box = box[2];
				if (!box) return;
				box = this.processList[box];
				if (!box) return;
				if ((typeof box.item[1]) === 'string') return;
				box.item[1][4] = msg.status;
				box.update();
			}
		},
		goBack () {
			this.$router.push({ path: '/conversation' });
		},
		async changeTitle (title) {
			this.conversation.title = title;
			this.conversation.time = Date.now();
			await DataCenter.set(DBNames.assistant, 'history', this.chatID, this.conversation);
			this.title = title;
			this.$refs.inputter.focusInput();
		},
		onInput (content) {
			this.addChat(0, content);
			this.appendChat(content);
		},
		onDownload () {
			var markdown = ["#\t" + this.conversation.title], inner = getTimeString(this.conversation.time);
			inner = ['>\t对话时间: ' + inner];
			inner.unshift('>\t专业领域: ' + this.conversation.expert);
			inner.push('>\t能力等级: ' + this.conversation.level);
			markdown.push(inner.join('\n'));
			markdown.push('\n-----\n');
			inner = -1;
			this.conversation.history.forEach(item => {
				if (item[0] === 0) {
					if (inner !== 0) markdown.push('##\t我');
					markdown.push(item[1].replace(/^\s+|\s+$/g, '').replace(/\n{3,}/g, '\n\n'));
					inner = 0;
				}
				else if (item[0] === 1) {
					if (inner !== 1) markdown.push('##\tAI助手');
					markdown.push(item[1].replace(/^\s+|\s+$/g, '').replace(/\n{3,}/g, '\n\n'));
					inner = 1;
				}
				else if (item[0] === 2) {
					if (inner !== 2) markdown.push('##\t调用插件');
					let ctx = '>\t`' + item[1][2] + '`';
					if (item[1][4] === 'success') ctx += '  【成功】';
					else if (item[1][4] === 'failed') ctx += '  【失败】';
					else ctx += '  【终止】';
					markdown.push(ctx);
					inner = 2;
				}
			});
			markdown = markdown.join('\n\n');

			var blob = new Blob([markdown], { type: 'text/plain' });
			var link = URL.createObjectURL(blob);
			var downloader = newEle('a');
			downloader.setAttribute('href', link);
			downloader.setAttribute('download', 'conversation.md');
			downloader.click();
		},
		async appendChat (text) {
			this.$refs.inputter.inactive();
			this.addChat(4, "分析中……", false);

			try {
				let result = await sendRequest('/conversation', {
					event: 'append',
					session: this.chatID,
					model: this.model,
					level: this.level,
					expert: this.expert,
					content: text,
				});
				console.log(result);
				if (this.level > 0) {
					let json = parseXMLV1(result);
					if (typeof json !== 'string') {
						result = json.reply;
						if (json.questions) {
							result += '\n\n#### 请问：\n\n';
							result += json.questions.map((line, i) => (i + 1) + '. ' + line).join('\n');
						}
						if (json.hints) {
							result += '\n\n\n----\n\n\n#### 了解更多：\n\n';
							result += json.hints.map((line, i) => (i + 1) + '. ' + line).join('\n');
						}
					}
					else {
						result = json.replace(/^\s+|\s+$/gi, '');
					}
				}
				this.$refs.chatBox.forEach(box => {
					if (!box.$el._running) return;
					if ((typeof box.item[1]) === 'string') return;
					box.item[1][4] = 'stopped';
					box.update();
				});
				if (!!result) this.addChat(1, result);
				await wait();
				this.$refs.inputter.reset();
			}
			catch (err) {
				console.error(err);
				this.addChat(3, err.message || err.msg || err.data || err);
			}

			this.removeRunningHint();
			this.$refs.inputter.active();
			this.$refs.inputter.focusInput();
		},
		async addChat (type, content, saveHistory=true) {
			var item = [type, content, this._index];
			this._index ++;
			this.list.push(item);
			if (saveHistory) {
				this.conversation.history.push(item);
				this.conversation.time = Date.now();
				await DataCenter.set(DBNames.assistant, 'history', this.chatID, this.conversation);
			}
		},
		removeRunningHint () {
			var dels = this.list.map((item, i) => {
				if (item[0] === 4) return i;
				else return -1;
			}).filter(idx => idx >= 0);
			this.$refs.chatBox.forEach((box, i) => {
				if (box.$el._isRunningHint === true || dels.includes(i)) {
					box.$el.style.display = 'none';
					box.$el._isRunningHint = false;
				}
			});
		},
		updatePosition () {
			if (!this.$refs.chatBox || !this.$refs.chatBox.length) return;
			this.$refs.chatBox.forEach(box => {
				if (box.$el._isRunningHint) {
					box.$el.parentElement.appendChild(box.$el);
				}
			});
			var last = this.$refs.chatBox[this.$refs.chatBox.length - 1];
			last.$el.scrollIntoView();
		}
	}
};
</script>